/** @jsx jsx */
// eslint-disable-next-line
import { css, jsx } from '@emotion/react';
// eslint-disable-next-line
import React from 'react';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';
import Layout from '../components/Layout';
import { ReadProgress } from '../components/ReadProgress';
import { TagLink } from '../components/TagLink';
import { contentArea } from '../constants';
import MDXRenderer from 'gatsby-plugin-mdx/mdx-renderer';

const ContentTemplate = ({ data }) => {
  const {
    mdx: { frontmatter, body },
  } = data;
  const showReadProgress = false;

  return (
    <Layout>
      <Helmet title={`${frontmatter.title}`} />
      {showReadProgress && <ReadProgress />}
      <div css={contentArea}>
        <h1>{frontmatter.title}</h1>
        <h4>{frontmatter.date}</h4>
        <MDXRenderer>{body}</MDXRenderer>
        {frontmatter.tags && (
          <div>
            <span>Tagged: </span>
            {frontmatter.tags.map(tag => (
              <TagLink key={tag} tag={tag} />
            ))}
          </div>
        )}
      </div>
    </Layout>
  );
};

export default ContentTemplate;

export const pageQuery = graphql`
  query($path: String!) {
    mdx(frontmatter: { draft: { ne: true }, path: { eq: $path } }) {
      body
      frontmatter {
        date(formatString: "MMMM D, YYYY")
        path
        title
        tags
      }
    }
  }
`;
